import '@fortawesome/fontawesome-free/css/all.min.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import StylesProvider from '@material-ui/styles/StylesProvider';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import Theme from 'components/layout/Theme';
import RemindPaymentModal from 'components/mocules/RemindPaymentModal';
import { DEFAULT_THUOCSI_DESCRIPTION, DEFAULT_THUOCSI_LONG_TITLE, DEFAULT_THUOCSI_TITLE, VISITED_URLS_STORAGE_KEY } from 'constants/data';
import { MOBILE } from 'constants/Device';
import { DOMAINS_THUOCSI } from 'constants/Paths';
import { AuthProvider, LoadingRoute } from 'context/Auth';
import { CartContextProvider } from 'context/Cart/CartContext';
import ContextProviderComposer from 'context/ContextProviderComposer';
import { NotiContextProvider } from 'context/Notifications';
import { PopupProvider } from 'context/Popup';
import { ProductContextProvider } from 'context/Product/context';
import { SearchProvider } from 'context/Search';
import { SettingProvider } from 'context/Settings';
import { TicketProvider } from 'context/Ticket';
import App from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider as StyledTheme } from 'styled-components';
import { DOMAIN_WEB_HOST, ENV } from 'sysconfig';
import gtag, { GA_TRACKING_ID_V2 } from 'utils/gtag';
import MonitorUtils from 'utils/MonitorUtils';
import { Provider, useCreateStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import '../styles/globals.css';
import '../styles/icomoon.css';
import Segmentation from 'components-v2/segmentation/Segmentation';
import { usePreventCapture } from 'hooks/usePreventCapture';

const ChatThuocSi = dynamic(() => import('utils/chat-thuocsi/ChatThuocsi'), { ssr: false });
const Insider = dynamic(() => import('components-v2/tracking-script/Insider'), { ssr: false });

const PLATFORM = 'WEB';
const DOMAIN = 'thuocsi.vn';

const { publicRuntimeConfig } = getConfig();

const pathNoRedirect = [
  '/',
  '/list-product',
  '',
  '/flagship-store',
  '/flagship-store/[slug]/list-product',
  '/flagship-store/[slug]',
  '/flagship-store/durex',
  '/flagship-store/durex/list-product',
  '/flagship-store/durex/rewards',
  '/flagship-store/sanofi',
  '/flagship-store/sanofi/list-product',
  '/flagship-store/sanofi/rewards',
  '/phanhoi',
  '/qr',
  '/qr/[code]',
  'phanhoi',
  'feedback',
  '/feedback',
  '/flagship-store/[slug]/flagship-rewards',
  '/flagship-store/[slug]/rewards',
  '/tracking-order',
  '/maintain',
];

const MyApp = (props) => {
  const { Component, pageProps, host = '', shieldUrl, shieldUrlImport, shieldUrlNoScript } = props;

  const { user = {}, SEO_CONFIG = {}, initialZustandState = {} } = pageProps || {};

  const { thumbnailMap = {}, defaultThumbnail = {} } = initialZustandState || {};

  const router = useRouter();
  const { query, pathname } = router || {};
  const reffererUrl = useRef('');
  const createStore = useCreateStore({ ...pageProps.initialZustandState, user });
  const { referCode, action, login, forgetpasscode, t, token, redirectUrl } = query || {};
  const isShowingLogin = login === 'true';
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const isTrackingMobileV2 = useMobileV2((state) => state.isTrackingMobileV2);

  // Dùng SEO default là thông tin SEO của trang chủ
  const seoInfo = thumbnailMap[router.asPath] || {};

  usePreventCapture();

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // nếu khách vào thuocsi.com.vn -> cần redirect lại domain chính
    if (window && window?.location?.href?.indexOf('thuocsi.com.vn') >= 0) {
      router.push(`${DOMAIN_WEB_HOST}`);
    }

    // Prevent pull to refresh for chat mobile
    if (router.pathname === '/conversations') {
      document.querySelector('html')?.classList.add('preventPullToRefresh');
    } else {
      document.querySelector('html')?.classList?.remove('preventPullToRefresh');
    }
  }, []);

  const saveUrlWhenChangeRoute = () => {
    try {
      const visitedUrls = JSON.parse(localStorage.getItem(VISITED_URLS_STORAGE_KEY) || '[]');

      const currentUrl = window?.location?.href;
      if (currentUrl && currentUrl?.startsWith('http')) {
        let newVisitedUrls = currentUrl;

        if (visitedUrls[visitedUrls.length - 1] !== currentUrl) {
          newVisitedUrls = [...visitedUrls, currentUrl];
        }

        if (newVisitedUrls.length > 5) {
          // Chỉ lấy 5 urls gần nhất
          newVisitedUrls = newVisitedUrls.slice(newVisitedUrls.length - 5, newVisitedUrls.length);
        }

        localStorage.setItem(VISITED_URLS_STORAGE_KEY, JSON.stringify(newVisitedUrls));
      }
    } catch (e) {
      localStorage.setItem(VISITED_URLS_STORAGE_KEY, '[]');
    }
  };

  // DOMAINS_THUOCSI -> domain thuocsi sẽ chạy, các host khác proxy tới thuocsi sẽ bị đá lại domain chính
  useEffect(() => {
    if (DOMAINS_THUOCSI?.indexOf(host) === -1 && !pathNoRedirect.includes(router.pathname)) {
      router.push(`${DOMAIN_WEB_HOST}${router.asPath}`);
    }
    saveUrlWhenChangeRoute();
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChange = async (url) => {
      gtag.pageview(url, user);
      MonitorUtils.sendPageEvent({ url, reffererUrl: reffererUrl.current });
      reffererUrl.current = url;
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const sendAttributes = ({ customerID, email, phone, baseUrl, sessionId, rootAccountID }) => {
    fetch('/api/shield-signature', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        platform: PLATFORM,
        env: ENV === 'prd' || ENV === 'uat' ? 'prd' : 'stg',
        userId: customerID?.toString(),
        email,
        mobileNumber: phone,
        fullUri: baseUrl,
        sessionId,
        rootAccountID: rootAccountID ? rootAccountID?.toString() : undefined,
      }),
    }).catch((error) => {
      console.error('Failed to fetch:', error);
    });
  };

  useEffect(() => {
    const { name, customerID } = user || '';
    const { pathname: pathNameWindow } = window.location;
    const baseUrl = pathNameWindow;
    const { rootAccountID } = user?.session || { rootAccountID: '' };

    const callBackFunc = async () => {
      window.getDeviceResult().then(
        async (res) => {
          const deviceIntelligence = res.result?.device_intelligence;
          const sessionId = res.result?.session_id;
          const cloneResult = JSON.parse(JSON.stringify(res.result));
          if (cloneResult.timestamp === 0) {
            delete cloneResult.timestamp;
          }
          delete cloneResult.device_intelligence;
          await fetch(shieldUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              deviceIntelligence,
              others: {
                ...cloneResult,
              },
              error: null,
              userInfo: {
                id: customerID?.toString(),
                name,
                rootAccountID: rootAccountID ? rootAccountID?.toString() : undefined,
              },
              screen: pathname,
              platform: PLATFORM,
              action: 'SUCCESS',
              appName: DOMAIN,
              fullURI: baseUrl,
            }),
          }).catch((error) => {
            console.error('Failed to fetch:', error);
          });
          if (user) {
            sendAttributes({ ...user, baseUrl, sessionId, rootAccountID });
          }
        },
        async (error) => {
          await fetch(shieldUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              deviceIntelligence: {},
              others: {},
              error,
              userInfo: {},
              screen: pathname,
              platform: PLATFORM,
              action: 'FAILURE',
              appName: DOMAIN,
              fullURI: baseUrl,
            }),
          }).catch((error) => {
            console.error('Failed to fetch:', error);
          });
        },
      );
    };

    // Load script
    const script = document.createElement('script');
    script.src = shieldUrlImport;
    script.async = true;
    script.charset = 'UTF-8';
    script.onload = callBackFunc; // Call your function when script is loaded

    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, [user?.customerID]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta httpEquiv="Expires" content="-1" />
        <meta name="keywords" content="thuốc sỉ" />
        <title>{seoInfo?.pageTitle || SEO_CONFIG?.title || defaultThumbnail?.pageTitle || DEFAULT_THUOCSI_LONG_TITLE}</title>
        <meta name="title" content={seoInfo?.title || SEO_CONFIG?.title || defaultThumbnail?.title || DEFAULT_THUOCSI_TITLE} />
        <meta
          name="description"
          content={seoInfo?.description || SEO_CONFIG?.description || defaultThumbnail?.description || DEFAULT_THUOCSI_DESCRIPTION}
        />

        <meta property="og:site_name" content={DEFAULT_THUOCSI_TITLE} />
        <meta property="og:title" content={seoInfo?.title || SEO_CONFIG?.ogTitle || defaultThumbnail?.title || DEFAULT_THUOCSI_TITLE} />
        <meta property="og:image" content={seoInfo?.imageUrl || SEO_CONFIG?.ogImage || defaultThumbnail?.imageUrl} />
        <meta property="og:image:secure_url" content={seoInfo?.imageUrl || SEO_CONFIG?.ogImage || defaultThumbnail?.imageUrl} />
        <meta property="og:image:alt" content={seoInfo?.title || SEO_CONFIG?.ogImageAlt || defaultThumbnail?.title || DEFAULT_THUOCSI_LONG_TITLE} />
        <meta
          property="og:description"
          content={seoInfo?.description || SEO_CONFIG?.ogDescription || defaultThumbnail?.description || DEFAULT_THUOCSI_DESCRIPTION}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "thuocsi.vn",
            "description": "thuocsi.vn được thành lập từ năm 2018, là một trong những startup thành công dẫn đầu trong lĩnh vực công nghệ về y tế và dịch vụ y tế, cung cấp thuốc sỉ giá tốt.",
            "logo": "https://cdn-web-next.thuocsi.vn/images/logo/brand-logo.svg",
            "@id": "https://thuocsi.vn/",
            "url": "https://thuocsi.vn/",
            "openingHours": "Mo-Su 08:00-20:00",
            "telephone": "1800 2038",
            "email": "hotro@buymed.com",
            "address": {
            "streetAddress": "8 Floor, Vincom Center Dong Khoi, 72 Le Thanh Ton Street, Ben Nghe Ward, District 1, Ho Chi Minh City",
            "addressLocality": "Ho Chi Minh City",
            "postalCode": "70000",
            "addressCountry": "Vietnam"
            },
            "sameAs": [
              "https://www.facebook.com/thuocsivn/",
              "https://zalo.me/1836616064223034882",
              "https://www.linkedin.com/company/thuocsivn/",
              "https://www.tiktok.com/@thuocsi.vietnam?"
                ]
              }`}
        </script>
        <noscript>
          <iframe src={shieldUrlNoScript} width="0" height="0" style={{ display: 'none' }} title="Shield Frame" />
        </noscript>
      </Head>
      {/* <NextNprogress color="#29D" startPosition={0.3} stopDelayMs={200} height="3" options={{ showSpinner: false }} /> */}
      <StylesProvider injectFirst>
        <StyledTheme theme={Theme}>
          <MuiThemeProvider theme={Theme}>
            <CssBaseline />
            {/* Context composer */}
            <Provider createStore={createStore}>
              <ContextProviderComposer
                contextProviders={[
                  <AuthProvider
                    key="AuthProvider"
                    isShowingLogin={isShowingLogin}
                    referCode={referCode}
                    forgetpasscode={forgetpasscode}
                    action={action}
                    tokenv1={t}
                    token={token}
                    redirectUrl={redirectUrl}
                    // initAccount={accountInfo}
                    initUser={user}
                  />,
                  <ProductContextProvider key="ProductContextProvider" initUser={user} />,
                  <CartContextProvider key="CartContextProvider" />,
                  <NotiContextProvider key="NotiContextProvider" initUser={user} />,
                  <SettingProvider key="SettingProvider" />,
                  <SearchProvider key="SearchProvider" />,
                  <TicketProvider key="TicketProvider" />,
                  <PopupProvider key="PopupProvider" />,
                ]}
              >
                <LoadingRoute>
                  <Component {...pageProps} />

                  <RemindPaymentModal />
                  <ChatThuocSi key={pageProps?.isAuthenticated || null} />
                  <ToastContainer limit={2} pauseOnHover={false} hideProgressBar autoClose={2000} closeOnClick />
                </LoadingRoute>
              </ContextProviderComposer>
            </Provider>
          </MuiThemeProvider>
        </StyledTheme>
      </StylesProvider>

      <Insider host={host} user={user} zustandState={initialZustandState} />
      <Segmentation />

      {ENV === 'prd' && isMobileV2 && isTrackingMobileV2 && (
        <>
          <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID_V2}&l=dataLayerGAV2`} />
          <Script strategy="lazyOnload">
            {`
                    window.dataLayerGAV2 = window.dataLayerGAV2 || [];
                    function gtagGAV2(){dataLayerGAV2.push(arguments);}
                    gtagGAV2('js', new Date());
                    gtagGAV2('config', '${GA_TRACKING_ID_V2}', {
                    page_path: window.location.pathname,
                    });
                `}
          </Script>
        </>
      )}
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const SHIELD_URL_IMPORT = `https://${process.env.SECRET_KEY_DETECT}.cloudfront.net/shdfp.js?SITE_ID=${process.env.SECRET_ID_DETECT}&TYPE=JS&DE=1&AUTO=0`;
  const SHIELD_URL_NO_SCRIPT = `https://${process.env.SECRET_ID_DETECT}.csftr.com/shield-fp/v1/api/web/noscript?site_id=${process.env.SECRET_ID_DETECT}`;
  const time = +new Date();
  const appProps = await App.getInitialProps(appContext);
  let host = '';
  try {
    host = appContext.ctx.req.headers.host || '';
  } catch (error) {
    host = "can't detect host";
  }

  let isMobile = '';
  try {
    const UA = appContext.ctx.req.headers['user-agent'];
    isMobile = Boolean(UA.match(MOBILE));
  } catch (error) {
    isMobile = `can not detect device - ${error}`;
  }

  return {
    ...appProps,
    host,
    buildId: publicRuntimeConfig.buildId,
    timeAPP: +new Date() - time,
    shieldUrl: `${process.env.BUYMED_DOMAIN}/monitoring/collector/v1/shield/result`,
    shieldUrlImport: SHIELD_URL_IMPORT,
    shieldUrlNoScript: SHIELD_URL_NO_SCRIPT,
    pageProps: {
      isMobile: !!isMobile,
    },
  };
};

export default MyApp;

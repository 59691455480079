import { PreventSearchKeywords, PreventSearchKeywordsAlias } from 'constants/data/search';
import { HTTP_STATUS } from 'constants/Enums/http';
import { IS_ENCRYPTED_PRICE } from 'sysconfig';
import { sanitize } from 'utils/ObjectUtils';
import { changeAlias } from 'utils/StringUtils';
import { GET, getData, GET_ALL, POST } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
const ORDER_API_PREFIX = '/marketplace/order/v2';
const PRODUCT_API_PREFIX = '/marketplace/product/v2';

export async function loadDataManufacturer(ctx) {
  const res = await GET_ALL({
    ctx,
    url: `${PRODUCT_API_PREFIX}/manufacturer/list`,
    isBasic: true,
  });
  return getData(res)?.sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export async function getTabs({ ctx }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tabs/active`,
    params: { limit: 100 },
    isBasic: true,
  });
}

export async function getDeals({ ctx, params }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/deal/list`,
    params,
    isBasic: true,
  });
}

export async function getDealsDetail({ ctx, body, params }) {
  return POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/deal/list`,
    body,
    params,
    isBasic: true,
  });
}

export async function getSettingTags({ ctx, limit = 100 }) {
  return GET_ALL({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tag/list`,
    isBasic: true,
    limit,
  });
}

export async function getTags({ ctx, offset = 0, limit = 100, ids = [], codes = [], getTotal = false, q }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tag/list`,
    params: {
      offset,
      limit: codes.length > 0 ? (ids.length > 0 ? ids.length : codes.length) : limit,
      q: q ? JSON.stringify(q) : null,
      ids: ids.join(','),
      codes: codes.join(','),
      getTotal,
    },
  });
}

export async function getProducts({ ctx, codes, limit, customerLevel, locationCode, getPrice = true, customerId }) {
  return POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/search/list`,
    body: { skus: codes, getProduct: true, locationCode, customerLevel, getPrice, customerId },
    params: { limit },
  });
}

export async function getProductsByIds({ ctx, ids, ...restProps }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/list`,
    params: { ids },
    ...restProps,
  });
}

export async function getProductsByCodes({ ctx, codes = [], ...restProps }) {
  return POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/list`,
    body: { codes },
    ...restProps,
  });
}

export async function getDescriptionById({ ctx, productId, ...restProps }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/description`,
    params: { q: JSON.stringify({ productID: productId }) },
    ...restProps,
  });
}

export async function getDescriptionBySku({ ctx, sku }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/product/description`,
    params: {
      q: JSON.stringify({
        code: sku.split('.')[1] || '',
      }),
    },
  });
}

export async function getSkusLimit({ ctx, skuCodes }) {
  return POST({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku-limit/list`,
    body: {
      skuCodes,
    },
  });
}

export async function getSkusBuyed({ ctx, skuCodes }) {
  return POST({
    ctx,
    url: `${ORDER_API_PREFIX}/sku-limit/history-today`,
    body: {
      skuCodes,
    },
  });
}

export async function getSkusByProductId({ ctx, productIds }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku-item/list`,
    params: {
      productIds,
    },
    isBasic: true,
	isAuth: false,
  });
}

export async function getFuzzySearchClient({ body, ctx, ...restProps }) {
  if (body && body.extraTag && body.filter && body.filter.tag) {
    body.filter.tags = [body.extraTag, body.filter.tag];
    delete body.filter.tag;
  } else if (body.extraTag) {
    body.filter.tag = body.extraTag;
  }
  const { text } = body || {};
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;
    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(changeAlias(textSearch)) >= 0
    ) {
      return { status: HTTP_STATUS.NotFound, msg: 'Keyword is not valid' };
    }
  }
  if (typeof body.isAvailable === 'string') {
    body.isAvailable = body.isAvailable === 'true';
  }

  const urlSearch = IS_ENCRYPTED_PRICE ? `${FRONTEND_APIS_PREFIX}/search/fuzzy-encrypted` : `${FRONTEND_APIS_PREFIX}/search/fuzzy`;

  if (restProps.v2) {
    delete restProps.v2;
    body.queryOption = {
      ...body.queryOption,
      consumedMaxQuantity: true,
      sellerInfo: true,
      isGetSalePriceLabel: restProps.isGetSalePriceLabel || false,
      quantityInCart: true,
      getWishList: true,
      getShadowSKU: !!restProps.isQuickOrder,
      isReplacePriceAfterVoucher: true,
      isGetSKUReplace: true,
    };
    //
    if (body.filter?.categoryFilters?.length) {
      for (let i = 0; i < body.filter.categoryFilters.length; i += 1) {
        if (body.filter.categoryFilters[i].efficacyCodes?.length) {
          body.filter.categoryFilters[i].categoryCodes = [];
          body.filter.categoryFilters[i].code = '';
        }
      }
    }
    //
    return POST({
      ctx,
      url: urlSearch,
      body,
      ...restProps,
    });
  }

  // if (IS_ENCRYPTED_PRICE) {
  body.queryOption = {
    ...body.queryOption,
    // isRawFuzzy: true,
    quantityInCart: true,
    getWishList: true,
    isReplacePriceAfterVoucher: true,
  };
  // }
  return POST({
    ctx,
    url: urlSearch,
    body,
    ...restProps,
  });
}

export async function getDataProductList({ body, ctx, ...restProps }) {
  if (body?.extraTag && body?.filter?.tag) {
    body.filter.tags = [body.extraTag, body.filter.tag];
    delete body.filter.tag;
  } else if (body?.extraTag) {
    body.filter.tag = body.extraTag;
  }
  const { text } = body || {};
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;
    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(changeAlias(textSearch)) >= 0
    ) {
      return { status: HTTP_STATUS.NotFound, msg: 'Keyword is not valid' };
    }
  }
  if (typeof body.isAvailable === 'string') {
    body.isAvailable = body.isAvailable === 'true';
  }

  body.queryOption = {
    ...body.queryOption,
    quantityInCart: true,
    getWishList: true,
    isReplacePriceAfterVoucher: true,
  };

  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/list`,
    body,
    ...restProps,
  });
}

export async function getProductGroupsClient() {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/featured-product-group`,
    body: {
      limit: 15,
      q: {
        productGroups: [
          {
            index: 1,
            tags: ['RENHAT'],
          },
          // {
          //   index: 2,
          //   tags: ['GY7G'],
          // },
          // {
          //   index: 3,
          //   tags: ['BANCHAY'],
          // },
          {
            index: 4,
            tags: ['V2U1'],
          },
          {
            isDeal: true,
            index: 5,
          },
        ],
        isReplacePriceAfterVoucher: true,
      },
    },
  });
}

export async function getProductQuickOrder({
  offset = 1,
  text,
  limit,
  isReplacePriceAfterVoucher = false,
  isGetSKUReplace = false,
  quickOrderPage = false,
}) {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/quick-order/fuzzy-encrypted`,
    body: {
      offset,
      text,
      limit,
      isReplacePriceAfterVoucher,
      queryOption: {
        isReplacePriceAfterVoucher: true,
        isGetSKUReplace,
        quickOrderPage,
      },
    },
  });
}

export async function getCartBySeller(isFistTimeGetCart = false, promosRemoved = '') {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/screen/cart/info`,
    params: {
      queryOption: `price,consumedMaxQuantity,sellerInfo,isGetSKUReplace,cartPage`,
      getVoucherAuto: !!isFistTimeGetCart,
      redeemCodeRemovedStr: promosRemoved || '',
    },
  });
}

export async function getBlockList({ ctx, params }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/screen/product/block`,
    params,
  });
}

export async function getReplaceProduct({ offset = 0, limit = 10, slug = '', sku }) {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/search/substitutes-encrypted`,
    body: sanitize({
      sku,
      offset,
      limit,
      slug,
    }),
  });
}

export const postImpressionEvent = async ({body}) => {
  return POST({
    url: `${PRODUCT_API_PREFIX}/impression-event`,
    body: sanitize(body)
  })
}

export default {
  loadDataManufacturer,
  getTabs,
  getSettingTags,
  getDeals,
  getDealsDetail,
  getProducts,
  getProductsByIds,
  postImpressionEvent
};

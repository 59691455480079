// CUSTOMER
export const CUSTOMER_API_PREFIX = '/marketplace/customer/v1';
export const CUSTOMER_API = {
  REGISTER: `${CUSTOMER_API_PREFIX}/register`,
  INFO: `${CUSTOMER_API_PREFIX}/me`,
  WALLET: `${CUSTOMER_API_PREFIX}/wallets`,
  ORDER: `${CUSTOMER_API_PREFIX}/orders`,
  REFERRAL: `${CUSTOMER_API_PREFIX}/me/reference/list`,
  PROMO: `${CUSTOMER_API_PREFIX}/promos`,
  SEND_SMS: `${CUSTOMER_API_PREFIX}/me/reference`,
  RETRY_SEND_SMS: `${CUSTOMER_API_PREFIX}/me/reference/sms`,
  REGISTER_GUEST: `${CUSTOMER_API_PREFIX}/guest`,
  BANK_ACCOUNT: `${CUSTOMER_API_PREFIX}/me/account/bank`,
  BANK_ACCOUNT_INFO: `${CUSTOMER_API_PREFIX}/me/bank`,
  INVOICE_INFO: `${CUSTOMER_API_PREFIX}/me/invoice-info`,
  INVOICE_LIST: `${CUSTOMER_API_PREFIX}/me/invoice-info/list`,
  ADDRESS_ACCOUNT: `${CUSTOMER_API_PREFIX}/me/account/address`,
  LOYALTY_LIST: `${CUSTOMER_API_PREFIX}/loyalty/list`,
  HISTORY_LOYALTY_LIST: `${CUSTOMER_API_PREFIX}/me/history-loyalty/list`,
  LOYALTY: `${CUSTOMER_API_PREFIX}/me/loyalty`,
  UPLOAD_FILE: `${CUSTOMER_API_PREFIX}/upload/file`,
  SIGN_IN: `${CUSTOMER_API_PREFIX}/authentication`,
  LEVEL_LIST: `${CUSTOMER_API_PREFIX}/level/list`,
  UPDATE_INFO_ACCOUNT: `${CUSTOMER_API_PREFIX}/me/basic-info`,
  UPDATE_INFO_ENTERPRISE: `${CUSTOMER_API_PREFIX}/me/business-info`,
  CACULATE_POINTS_CUSTOMER: `${CUSTOMER_API_PREFIX}/setting`,
  PRIORITY_CUSTOMER: `${CUSTOMER_API_PREFIX}/priority-customer`,
  REQUEST_LICENSE: `${CUSTOMER_API_PREFIX}/request-license`,
  LOGIN_Y360_ACCOUNT: `${CUSTOMER_API_PREFIX}/y360/login`,
  LOGOUT_Y360_ACCOUNT: `${CUSTOMER_API_PREFIX}/y360/logout`,
  RESET_LOYALTY_POINT: `${CUSTOMER_API_PREFIX}/me/reset-point`,
};

export const PRODUCT_API_PREFIX = '/marketplace/product/v2';
export const PRODUCT_API = {
  INGREDIENT_PRODUCT_LIST: `${PRODUCT_API_PREFIX}/ingredients/list`,
  MANUFACTURER: `${PRODUCT_API_PREFIX}/manufacturers`,
  MANUFACTURER_LIST: `${PRODUCT_API_PREFIX}/manufacturer/list`,
  MANUFACTURER_DETAIL: `${PRODUCT_API_PREFIX}/manufacturer`,
  PRODUCT_LIST: `${PRODUCT_API_PREFIX}/search/list`,
  TAGS: `${PRODUCT_API_PREFIX}/tag/list`,
  TABS_ACTIVE: `${PRODUCT_API_PREFIX}/tabs/active`,
  DEALS: `${PRODUCT_API_PREFIX}/products/deal/list`,
  DEALS_DETAIL: `${PRODUCT_API_PREFIX}/deal/list`,
  SELLER: `${PRODUCT_API_PREFIX}/sku/list`,
  SKU_LIMIT: `${PRODUCT_API_PREFIX}/sku-limit/list`,
  PRODUCT_API_PREFIX: `${PRODUCT_API_PREFIX}/product/detail`,
};

// promotion
const PROMOTION_API_PREFIX = '/marketplace/promotion/v1';
export const PROMOTION_API = {
  PROMOTION_ALL: `${PROMOTION_API_PREFIX}/promotion`,
  PROMOTION_DETAI_VOUCHER_CODE: `${PROMOTION_API_PREFIX}/voucher`,
  PROMOTION_CAMPAIGN: `${PROMOTION_API_PREFIX}/campaign/active/list`,
  VOUCHER: `${PROMOTION_API_PREFIX}/me/voucher`,
  MYVOUCHER_HISTORY: `${PROMOTION_API_PREFIX}/me/voucher/history`,
  SELF_LOYALTY_LIST: `${PROMOTION_API_PREFIX}/me/loyalty/list`,
};

// lucky wheel
export const PROMOTION_LUCKY_WHEEL = {
  SPIN: `${PROMOTION_API_PREFIX}/lucky-wheel/spin`,
  SELF_LUCKY_WHEEL: `${PROMOTION_API_PREFIX}/me/lucky-wheel`,
  LUCKY_WHEEL_ITEMS: `${PROMOTION_API_PREFIX}/lucky-wheel-item/list`,
  SELF_LOG_LUCKY_WHEEL: `${PROMOTION_API_PREFIX}/me/lucky-wheel-log/list`,
  SELF_MISSION_LUCKY_WHEEL: `${PROMOTION_API_PREFIX}/me/lucky-wheel-mission/list`,
};

const MARKETING_API_PREFIX = '/marketplace/marketing/v1';
export const MARKETING_API = {
  BANNER: `${MARKETING_API_PREFIX}/banners/available`,
  MINI_BANNER_CAMPAIGN: `${MARKETING_API_PREFIX}/mini-banner/list`,
  MINI_BANNER_LOCATION: `${MARKETING_API_PREFIX}/mini-banner/location/list`,
  MINI_BANNER_IMAGE: `${MARKETING_API_PREFIX}/mini-banner/image/list`,
  CUSTOMER_MINI_BANNER_IMAGE: `${MARKETING_API_PREFIX}/customer/mini-banner/list`,
  CAMPAIGNS: `${MARKETING_API_PREFIX}/customer/campaign-box/list`,
};

// web service frontend-apis
export const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
export const FRONTEND_APIS = {
  FUZZY_SEARCH_ENCRYPTED: `${FRONTEND_APIS_PREFIX}/search/fuzzy-encrypted`,
  FUZZY_SEARCH: `${FRONTEND_APIS_PREFIX}/search/fuzzy`,
  CART: `${FRONTEND_APIS_PREFIX}/cart`,
  CART_ENCRYPTED: `${FRONTEND_APIS_PREFIX}/cart-encrypted`,
  FUZZY_SEARCH_LITE: `${FRONTEND_APIS_PREFIX}/search/fuzzy/lite`,
  ORDER: `${FRONTEND_APIS_PREFIX}/order`,
  POST_ORDER: `${FRONTEND_APIS_PREFIX}/order/payment`,
  PRICE_VOLATILITY: `${FRONTEND_APIS_PREFIX}/sku/price-volatility/list`,
  SELLER_SKIP_LOGIN: `${FRONTEND_APIS_PREFIX}/seller/product/skip-login`,
  CONTACT_ME: `${FRONTEND_APIS_PREFIX}/customer/contact-me`,
  CART_LITE: `${FRONTEND_APIS_PREFIX}/cart-lite`,
  CART_LITE_ENCRYPTED: `${FRONTEND_APIS_PREFIX}/cart-lite-encrypted`,
  YOUTUBE_LIVESTREAM: `${FRONTEND_APIS_PREFIX}/youtube/livestream`,
  VERIFY_TAX_CODE: `${FRONTEND_APIS_PREFIX}/invoice/tax-code/verify`,
  CATEGORY_TOTAL_PRODUCT: `${FRONTEND_APIS_PREFIX}/category/total-product`,
  CATEGORIES: `${FRONTEND_APIS_PREFIX}/product/category/list`,
  WISHLIST: `${FRONTEND_APIS_PREFIX}/me/wishlist`,
  TICKET_REASONS: `${FRONTEND_APIS_PREFIX}/ticket/reason-setting/list`,
  RECENT_SEARCH_TEXTS: `${FRONTEND_APIS_PREFIX}/customer/recent-search-texts`,
  YEAR_IN_REVIEW: `${FRONTEND_APIS_PREFIX}/me/order-report?year=2023`,
  MENU_LIST: `${FRONTEND_APIS_PREFIX}/menu-profile`,
  SELLER_LIST: `${FRONTEND_APIS_PREFIX}/seller/list`,
  SELLER_STORE_LIST: `${FRONTEND_APIS_PREFIX}/seller/stores/search`,
  VENDOR_STORE_LIST: `${FRONTEND_APIS_PREFIX}/seller/vendor-store/list`,
  SELF_LOYALTY_LIST: `${FRONTEND_APIS_PREFIX}/me/loyalty/list`,
  MENU_BAR: `${FRONTEND_APIS_PREFIX}/menu-bar`,
  FUZZY_SEARCH_QUICK_ORDER: `${FRONTEND_APIS_PREFIX}/quick-order/fuzzy-encrypted`,
  CART_PAGE: `${FRONTEND_APIS_PREFIX}/screen/cart/info`,
  WISH_LIST: `${FRONTEND_APIS_PREFIX}/screen/product/wishlist`,
  PRODUCT_LIST: `${FRONTEND_APIS_PREFIX}/screen/product/list`,
  PRODUCT_BLOCKS: `${FRONTEND_APIS_PREFIX}/screen/product/block`,
  PRODUCTS_REPLACE: `${FRONTEND_APIS_PREFIX}/search/substitutes-encrypted`,
};

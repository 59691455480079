import { getData } from 'clients/Clients';
import { loadDataIngredientClientMock } from 'clients/IngredientClient';
import { getAllVendorStore } from 'clients/SellerClient';
import SupplierClient from 'clients/SupplierClient';
import { BRAND_NAME } from 'constants/Enums';
import { useAuth } from 'context/Auth';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { createContext, useContext, useEffect, useState } from 'react';
import { DOMAIN_FLAGSHIP_STORE } from 'sysconfig';
import { convertArrayToMap } from 'utils/ArrUtils';

const SettingContext = createContext({});

export const SettingProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();

  // ingredients
  const [mapIngredients, setMapIngredients] = useState(new Map());
  const [ingredients, getIngredients] = useLocalStorage({
    key: 'ingredients',
    getValueDefault: async () => {
      const settingRes = await loadDataIngredientClientMock();
      const data = settingRes?.data;
      return data && data.length > 0 ? data : null;
    },
    fieldNames: ['id', 'name', 'slug', 'code', 'isMedicine', 'unsignedKey'],
  });

  useEffect(() => {
    if (ingredients && ingredients.length > 0) {
      setMapIngredients(convertArrayToMap(ingredients, 'code'));
    }
  }, [ingredients]);

  // -------------------------------------------------------------------------------------------------------------
  // Seller info
  const [mapSeller, setMapSeller] = useState(new Map());
  const [sellers, getSellers] = useLocalStorage({
    ttl: 300,
    key: 'sellerv5',
    getValueDefault: async () => {
      // get api mocks (gỉam data trả ra)
      const sellerRes = await SupplierClient.getSellersMock();
      const data = getData(sellerRes);
      return data && data.length > 0 ? data : null;
    },
    fieldNames: [
      'code',
      'isInternal',
      'name',
      'shortName',
      'sellerType',
      'slug',
      'landingPage',
      'fullName',
      'isVip',
      'avatar',
      'status',
      'statusStore',
      'storeSlug',
      'isStore',
      'sellerID',
    ],
  });

  useEffect(() => {
    if (sellers && sellers.length > 0) {
      setMapSeller(convertArrayToMap(sellers, 'code'));
    }
  }, [sellers]);
  // end sellers

  // --------------------------------------------------------------------
  // Seller info
  const [mapVendorStore, setMapVendorStore] = useState(new Map());
  const [vendorStores, getVendorStores] = useLocalStorage({
    key: 'vendorStores',
    getValueDefault: async () => {
      // get api mocks (gỉam data trả ra)
      const sellerRes = await getAllVendorStore({
        body: {
          q: { status: 'ACTIVE' },
        },
      });
      const data = getData(sellerRes);
      return data && data.length > 0 ? data : null;
    },
    fieldNames: [
      'code',
      'name',
      'url',
      'slug',
      'landingPage',
      'logo',
      'allProductTag',
      'primaryColor',
      'titleColor',
      'backgroundColor',
      'borderAvatarColor',
    ],
  });

  useEffect(() => {
    if (vendorStores && vendorStores.length > 0) {
      setMapVendorStore(convertArrayToMap(vendorStores, 'allProductTag'));
    }
  }, [vendorStores]);
  // end sellers

  useEffect(() => {
    if (!ingredients || ingredients.length === 0) {
      getIngredients();
    }
    if (isAuthenticated) {
      if (!sellers) {
        getSellers();
      }
      if (!vendorStores) {
        getVendorStores();
      }
    }
  }, [isAuthenticated]);

  // seller name
  const getNameSeller = (props) => {
    const { seller = {}, tags = [], isSkipShortName = false } = props;
    const { code } = seller;
    const isVAT = tags?.indexOf('HOADONNHANH') >= 0 || false;
    const sellerInfo = mapSeller?.get(code) || null;

    let sellerName = '';
    let isDisplayStore = false;
    let fullNameSeller = '';
    let individualNonVatSellerName = '';
    const { isVip = false, slug = '' } = sellerInfo || {};
    let linkSeller = sellerInfo?.landingPage?.startsWith('https://try.thuocsi.vn')
      ? sellerInfo?.landingPage
      : `/seller-products/${sellerInfo?.code}/${sellerInfo?.slugStore || sellerInfo?.sellerType == 'ENTERPRISE' ? sellerInfo?.slug : ''}`;
    let linkStore = `/seller/${slug}`;
    if (isVip) {
      linkStore = DOMAIN_FLAGSHIP_STORE.replace('{seller}', slug) || `/flagship-store/${slug}`;
    }

    // nếu không có sellerinfo => hiện rỗng
    if (sellerInfo) {
      if (sellerInfo.isInternal) {
        isDisplayStore = true;
        fullNameSeller = 'MEDX';
        if (isVAT) {
          sellerName = 'MEDX';
        }
      } else if (sellerInfo?.sellerType === 'ENTERPRISE') {
        isDisplayStore = true;
        sellerName = sellerInfo?.name || '';

        if (!isSkipShortName) {
          if (sellerInfo.shortName) {
            sellerName = sellerInfo?.shortName;
          }

          // fallback for all case
          if (sellerInfo.displayName) {
            sellerName = sellerInfo?.displayName;
          }
        }

        fullNameSeller = sellerInfo?.fullName || '';
      } else {
        sellerName = `Đối Tác Của ${BRAND_NAME}`;
        individualNonVatSellerName = `Nhà bán hàng ${sellerInfo?.sellerID}`;
        isDisplayStore = false;
        fullNameSeller = `Đối Tác Của ${BRAND_NAME}`;
        linkSeller = `/doitac/${code}`;
      }
    }
    return { sellerName, linkStore, linkSeller, isDisplayStore, fullNameSeller, individualNonVatSellerName, ...sellerInfo };
  };

  const isVendorStore = (tags) => {
    if (!tags || tags?.length <= 0) return false;

    return tags.some((tag) => mapVendorStore.has(tag));
  };

  const getVendorStoreByTags = (tags) => {
    if (!tags || tags?.length <= 0) return null;
    const vendorStore = tags?.find((tag) => mapVendorStore.has(tag));
    return mapVendorStore.get(vendorStore) || null;
  };

  return (
    <SettingContext.Provider
      value={{
        ingredients,
        getIngredients,
        mapIngredients,
        mapSeller,
        sellers,
        mapVendorStore,
        getNameSeller,
        isVendorStore,
        getVendorStoreByTags,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};

export const useSetting = () => useContext(SettingContext);

export default {
  SettingContext,
  useSetting,
};

import { getAllProvinces } from 'clients/AddressClient';
import { useCallback, useEffect } from 'react';
import { TTL_CACHE_LOCAL } from 'sysconfig';
import { CACHE_KEY, deserializeFunc, getWithExpiry, serializeFunc, setWithExpiry } from 'utils/localStorageUtils';

export function useProvinces({ ttl = TTL_CACHE_LOCAL * 1000 } = {}) {
  useEffect(() => {
    // Remove old localStorage data
    localStorage.removeItem('provincesList');
  }, []);

  const getAllProvincesCB = useCallback(async () => {
    return await fetchAllProvinces({ ttl });
  }, []);

  const getProvinceByCode = useCallback(async (provinceCode) => {
    const allProvinces = await getAllProvincesCB();
    return allProvinces.find((p) => p.code === provinceCode);
  }, []);

  const getProvinceOptions = useCallback(async () => {
    const allProvinces = await getAllProvincesCB();
    const options = (allProvinces || []).map((p) => ({
      label: p.name,
      value: p.code,
      regionCode: p.regionCode,
    }));

    return options;
  }, []);

  return {
    getProvinceByCode,
    getAllProvinces: getAllProvincesCB,
    getProvinceOptions,
  };
}

export async function fetchAllProvinces({ ttl = TTL_CACHE_LOCAL * 1000 } = {}) {
  const FIELDS = ['code', 'name', 'regionCode'];

  // Case 1: cache hit
  const cachedProvinces = getWithExpiry(CACHE_KEY.PROVINCES);
  if (cachedProvinces) {
    return cachedProvinces.map((data) => deserializeFunc(data, FIELDS));
  }

  // Case 2: cache miss -> Fetch missing data, then cache
  const res = await getAllProvinces();

  if (res.status !== 'OK') {
    console.error('[Error] Get regions', res);
    return [];
  }

  const provinces = (res.data || []).sort((a, b) => a.name.localeCompare(b.name)).map((p) => serializeFunc(p, FIELDS));

  // Update cache
  setWithExpiry(CACHE_KEY.PROVINCES, provinces, { ttl });

  return provinces.map((data) => deserializeFunc(data, FIELDS));
}

import { getCartItemList } from 'clients/CartClientV2';
import { GET, getData, isValid } from 'clients/Clients';
import MockClient from 'clients/MockClient';
import { PAGE_SIZE_30 } from 'constants/data';
import { SELLER_GROUP } from 'constants/Enums';
import { HTTP_STATUS } from 'constants/Enums/http';
import { ENUM_TAG_CODES } from 'constants/Tags';
import { convertArrayToMap } from 'utils/ArrUtils';
import { getProxyImageList } from 'utils/ImageUtils';
import { getInsiderRecommendation } from 'utils/Insider';

const PRODUCT_API_PREFIX = '/marketplace/product/v1';

export async function getDataProductRecommendation({ type }) {
  const insiderLocalData = JSON.parse(localStorage?.getItem('spUID') || '{}');
  const userId = insiderLocalData?.data || '';

  if (!userId) {
    return {
      status: HTTP_STATUS.NotFound,
      data: [],
    };
  }

  const insiderData = await getInsiderRecommendation({ type, userId });

  const productSlugs = insiderData?.map(({ url }) => url.substring(url.lastIndexOf('/') + 1, url.indexOf('?#ins_sr'))) || [];
  // const result = await ProductServiceV2.getDataProductsBySlugs({ slugs: productSlugs, isAvailable: true });
  const result = await MockClient.getProductBySlugs({ slugs: productSlugs });

  if (isValid(result)) {
    result.data = getSortedProducts(result.data);
  }

  return result;
}

export function getSortedProducts(products) {
  const medxNormalProducts = [];
  const medxKhuyenMaiProducts = [];
  const khuyenMaiProducts = [];
  const hoaDonNhanhProducts = [];
  const normalProducts = [];

  products?.forEach((product) => {
    if (product?.sellerCode === SELLER_GROUP.MEDX && product.tags?.includes(ENUM_TAG_CODES.HOADONNHANH)) {
      if (product.tags?.includes(ENUM_TAG_CODES.KHUYENMAI)) medxKhuyenMaiProducts.push(product);
      else medxNormalProducts.push(product);
    } else if (product.tags?.includes(ENUM_TAG_CODES.KHUYENMAI)) {
      khuyenMaiProducts.push(product);
    } else if (product.tags?.includes(ENUM_TAG_CODES.HOADONNHANH)) {
      hoaDonNhanhProducts.push(product);
    } else {
      normalProducts.push(product);
    }
  });

  return [...medxKhuyenMaiProducts, ...khuyenMaiProducts, ...medxNormalProducts, ...hoaDonNhanhProducts, ...normalProducts];
}

export const mapDataProduct = async ({ ctx, result }) => {
  let cartObject = new Map();

  // if (isValid(cartRes)) {
  //   const cart = cartRes.data[0];
  //   if (cart && !isEmpty(cart.cartItems)) {
  //     cartObject = convertArrayToMap(cart.cartItems, 'sku');
  //   }
  // }

  const cartRes = await getCartItemList({ ctx });

  if (isValid(cartRes)) {
    const cartItems = getData(cartRes);
    if (cartItems?.length > 0) {
      cartObject = convertArrayToMap(cartItems, 'sku');
    }
  }
  // eslint-disable-next-line no-param-reassign
  result.data = result.data.map((item) => ({
    ...item,
    imagesProxy: getProxyImageList(item?.imageUrls) || [],
    // unit: item.unit && item.unit === '<nil>' ? '' : item.unit,
    quantity: cartObject.get(item.sku)?.quantity || 0,
  }));
  return result;
};

export const loadDataProduct = async ({ ctx, isTotal }) => {
  const result = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/list`,
    params: {
      ...ctx.query,
      limit: PAGE_SIZE_30,
      getTotal: typeof isTotal !== 'undefined' ? isTotal : true,
    },
    isBasic: true,
  });
  if (!isValid(result)) return result;

  return mapDataProduct({ ctx, result });
};

async function loadProductWithCategory({ ctx, isTotal }) {
  const { query } = ctx;

  const { currentTab = '', sortBy = '', page = 0, q = '', slug: category } = query;
  const params = {
    currentTab,
    sortBy,
    page,
    q,
    category,
    limit: PAGE_SIZE_30,
    getTotal: typeof isTotal !== 'undefined' ? isTotal : true,
  };
  const result = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/list`,
    params,
    isBasic: true,
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result });
}

async function loadProductWithManufacturer({ ctx, isTotal }) {
  const { query } = ctx;

  const { currentTab = '', sortBy = '', page = 0, q = '', slug: manufacturers } = query;
  const params = {
    manufacturers,
    currentTab,
    sortBy,
    page,
    q,
    limit: PAGE_SIZE_30,
    getTotal: typeof isTotal !== 'undefined' ? isTotal : true,
  };
  const result = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/list`,
    params,
    isBasic: true,
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result });
}

export const getListTabs = async ({ ctx }) => {
  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tabs/active`,
    params: { limit: 20 },
    isBasic: true,
  });

  if (!isValid(res)) {
    return [];
  }
  return res.data;
};

export const getDeals = async ({ ctx, params }) => {
  const result = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/products/deal/list`,
    params,
    isBasic: true,
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result });
};

export const getSettingTags = async ({ ctx }) =>
  GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tags/list`,
    isBasic: true,
  });

export default {
  loadDataProduct,
  mapDataProduct,
  loadProductWithCategory,
  loadProductWithManufacturer,
  getListTabs,
  getSettingTags,
  getDeals,
};

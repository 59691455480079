export interface IInitialStateChatGuest {
  fullName: string | null;
  phoneNumber: string | null;
  guestID: string | null;
  sessionID: string | null;
  userID: number | null;
}

export interface IChatGuest {
  data: IInitialStateChatGuest;
  setInfoGuest: (payload: IInitialStateChatGuest) => void;
}

// deprecated 22Feb2023 - Dinh Tran
// export interface IStateChatGuest extends IInitialStateChatGuest {
//   setInfoGuest: (payload: IInitialStateChatGuest) => void;
// }

export const UPDATE_CART = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
  REMOVE: 'REMOVE',
} as const;

export const FETCH_STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;

export type FetchStatus = keyof typeof FETCH_STATUS;
export type UpdateCart = keyof typeof UPDATE_CART;

export interface PayLoad {
  // toggleSelectStore
  sellerCode: string;
  // setSelectProduct
  name: string;
  skuCode: string;
  isSelected: boolean;
  quantityInCart: number;
  // toggleProductImportant
  sku: string;
  type: string;
  isImportant: boolean;
}
export interface Options {
  isReload?: boolean;
  isNotify?: boolean;
}

// Payload Type
export interface SelectAllPayload {
  isSelected: boolean;
}

export interface SelectStorePayload {
  sellerGroup: string;
  isSelected: boolean;
  skus: string[];
}

export interface SelectProductPayload {
  sellerGroup: string;
  name: string;
  skuCode: string;
  isSelected: boolean;
  quantityInCart: number;
}

export interface ImportantProductPayload {
  cartNo: string;
  sku: string;
  type: string;
  isImportant: boolean;
}

export type UpdateCartPayload = { idRef?: string; type: Exclude<UpdateCart, 'REMOVE'>; newQuantity: number; product: ProductUpdate };
export type RemoveCartPayload = { idRef?: string; type: Extract<UpdateCart, 'REMOVE'>; newQuantity: number; product: ProductUpdate };
export type RemoveCartItemsSelectedPayload = { skus: string[] };

export interface ProductUpdate {
  sku: string;
  type: string;
  name: string;
  productId: number;
  sellerID: string;
  sellerCode: string;
  isDeal?: boolean;
  quantity?: number;
  price?: number;
  cartItemType?: string;
  salePrice?: string;
  slug?: string;
  eventSource?: string;
  recommendSKUs?: string;
  metric?: string;
  blockCode?: string;
}

export interface ProductRemove {
  sku: string;
  type: string;
  name: string;
  productId: number;
  sellerID: string;
  sellerCode: string;
  cartItemType: string;
  salePrice: string;
  slug: string;
}

export interface CartParams {
  cartNo?: string;
  page?: string;
  searchKey?: string;
  eventSource: string;
  eventScreen: string;
  host: string;
  source?: string;
}

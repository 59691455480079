import { GET, GET_ALL, POST, POST_ALL } from './Clients';

const SELLER_API_PREFIX = '/seller/core/v1';
const PRODUCT_API_PREFIX = '/marketplace/product/v2';
const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

// for server side
export async function getAllSellers({ ctx }) {
  return GET_ALL({
    ctx,
    url: `${SELLER_API_PREFIX}/account/list`,
    isBasic: true,
    isAuth: false,
  });
}

export async function getListBanner({ ctx, sellerCode, isActive = true }) {
  return GET({
    ctx,
    url: `${SELLER_API_PREFIX}/store/banner-for-web`,
    params: { q: JSON.stringify({ sellerCode, isActive }) },
  });
}

export async function getSellerInfo({ ctx, slug }) {
  return GET({
    ctx,
    url: `${SELLER_API_PREFIX}/account-by-slug`,
    params: { slug },
  });
}

export async function getStoreInfoForWeb({ ctx, slug }) {
  return GET({
    ctx,
    url: `${SELLER_API_PREFIX}/store/info-for-web`,
    params: { slug },
    isBasic: true,
  });
}

export async function getListStoreActive({ ctx, sellerCode, status = 'ACTIVE' }) {
  return GET({
    ctx,
    url: `${SELLER_API_PREFIX}/store/list`,
    params: { q: JSON.stringify({ status, sellerCode }) },
  });
}

// API for mock
export async function getAllStoreActive({ ctx, status = 'ACTIVE' }) {
  return GET_ALL({
    ctx,
    url: `${SELLER_API_PREFIX}/store/list`,
    params: { q: JSON.stringify({ status }) },
    limit: 20,
    isBasic: true,
    isAuth: false,
  });
}

export async function getProductBySeller({ ctx, params }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/sku/list`,
    params,
  });
}

// https://api.v2-stg.thuocsi.vn/seller/core/v1/account?sellerCode=4GQEM8DN9F
export async function getSellerByCode({ ctx, sellerCode }) {
  return GET({
    ctx,
    url: `${SELLER_API_PREFIX}/account`,
    params: { sellerCode },
    cache: true,
  });
}

export async function searchVendorStore({ ctx, body }) {
  return POST({
    ctx,
    url: '/seller/core/v1/vendor-store/list',
    body,
    isBasic: true,
    isAuth: false,
  });
}

export async function searchStoreByText({ ctx, params }) {
  return GET({
    ctx,
    url: '/seller/core/v1/account/list',
    params,
    isBasic: true,
    isAuth: false,
  });
}

export async function getAllVendorStore({ ctx, body }) {
  const url = '/seller/core/v1/vendor-store/list';
  return POST_ALL({
    ctx,
    url,
    body,
    isBasic: true,
    isAuth: false,
  });
}

export async function searchSellerStoreV2({ ctx, body }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/seller/stores/search`,
    body,
  });
}

export async function searchStoreByTextV2({ ctx, params }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/seller/list`,
    params,
  });
}

export async function searchVendorStoreV2({ ctx, body }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/seller/vendor-store/list`,
    body,
  });
}

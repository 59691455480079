import { FRONTEND_APIS, MARKETING_API } from 'constants/APIUriV2';
import { ENUMS_MINI_BANNER_IMAGE_LAYOUT } from 'constants/Enums/mini-banner';
import { GET } from './Clients';

const MARKETING_API_PREFIX = '/marketplace/marketing/v1';

export async function getListHashtagSearch({ ctx, isActive = true, limit = 100, isBasic = false } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/hashtag-search/list`,
    params: {
      limit,
      q: JSON.stringify({ isActive }),
    },
    isBasic,
  });
}

export async function getCountdownBarList({ctx, isBasic = true } = {}) {
  return GET({
	ctx,
    url: `${MARKETING_API_PREFIX}/countdownbar/list`,
    isBasic,
  });
}

export async function getMetaThumbnailList({ ctx, isBasic = true } = {}) {
  return GET({
	ctx,
    url: `${MARKETING_API_PREFIX}/meta-thumbnail/list`,
    isBasic,
  });
}

export async function getMenuBar({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/menu-bar`,
    isBasic,
  });
}

export async function getInsiderSetting({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/insider-setting`,
    isBasic,
  });
}

export async function getChatSetting({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/setting?key=chat_config`,
    isBasic,
  });
}

const getListBanner = (ctx) => {
  const url = MARKETING_API.BANNER;
  return GET({ url, ctx });
};
const getBannerNano = (ctx, params) => {
  const url = MARKETING_API.BANNER;
  return GET({ url, ctx, params });
};
const getCampaignList = (ctx, params) => {
  const url = MARKETING_API.CAMPAIGNS;
  return GET({ url, ctx, params });
};

const getProductFiles = ({ q = {}, isMienBac = false }) =>
  GET({ url: '/web/sanphammoi', params: { q: JSON.stringify(q), isMienBac }, mock: true, isAuth: false });

const getListBannerMock = () => {
  const url = '/web/banner';
  return GET({ url, mock: true, isAuth: false });
};

const getMostSearchMock = () => GET({ url: '/web/most-search', mock: true, isAuth: false });

const getProductPriceVolatility = ({ q = {}, regionCode, offset = 0, limit = 1000, getTotal }) =>
  GET({ url: FRONTEND_APIS.PRICE_VOLATILITY, params: { q: JSON.stringify(q), offset, limit, regionCode, getTotal } });

const getListMiniBannerCampaign = (ctx, q = {}, offset = 0, limit = 300) =>
  GET({ url: MARKETING_API.MINI_BANNER_CAMPAIGN, ctx, params: { q: JSON.stringify(q), offset, limit } });

const getCustomerMiniBannerList = (ctx, q = {}, offset = 0, limit = 300, isBasic = false) =>
  GET({ url: MARKETING_API.CUSTOMER_MINI_BANNER_IMAGE, ctx, params: { q: JSON.stringify(q), offset, limit }, isBasic });

const getListMiniBannerLocation = (ctx, q, offset = 0, limit = 300) =>
  GET({ url: MARKETING_API.MINI_BANNER_LOCATION, ctx, params: { q: JSON.stringify(q), offset, limit } });

const getListMiniBannerImage = (ctx, q, offset = 0, limit = 300) =>
  GET({ url: MARKETING_API.MINI_BANNER_IMAGE, ctx, params: { q: JSON.stringify(q), offset, limit } });

const getListMiniBannerImageByLocationCode = (ctx, location, offset = 0) =>
  GET({
    url: MARKETING_API.MINI_BANNER_IMAGE,
    ctx,
    params: {
      q: JSON.stringify({ campaignBannerLocationCode: location?.code }),
      offset,
      limit: ENUMS_MINI_BANNER_IMAGE_LAYOUT[location?.imageLayout]?.quantity || 5,
    },
  });

const getClientCustomerMiniBannerList = (q = {}, offset = 0, limit = 300, isBasic = false) =>
  GET({ url: MARKETING_API.CUSTOMER_MINI_BANNER_IMAGE, params: { q: JSON.stringify(q), offset, limit }, isBasic });

export const getCampaignListV2 = (params) => {
  const url = MARKETING_API.CAMPAIGNS;
  return GET({ url, params });
};

export default {
  getListBanner,
  getBannerNano,
  getCampaignList,
  getListBannerMock,
  getMostSearchMock,
  getProductFiles,
  getProductPriceVolatility,
  getListMiniBannerCampaign,
  getListMiniBannerLocation,
  getListMiniBannerImage,
  getListMiniBannerImageByLocationCode,
  getCustomerMiniBannerList,
  getClientCustomerMiniBannerList,
};

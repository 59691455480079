import { CartStore, Product, ProductQuickOrder, ProductReplace, Seller } from 'types/product';
import { decryptAES, genkey } from 'utils/decode';

export function convertProductQuickOrder(product): ProductQuickOrder {
  const {
    productId,
    slug,
    imageUrl,
    productName,
    skuCode = '',
    sellerId,
    sellerName,
    sellerCode,
    sellerUrl,
    tags = [],
    type,
    maxQuantity = 0,
    discountPercent,
    discountPriceEncrypted,
    priceEncrypted,
    subPriceEncrypted,
    skuReplace = [],
    limitMessage = '',
    quantityInCart = 0,
    isWishlist = false,
    labels = [],
    errorCode,
    errorMessage,
    warningMessage,
    productTags = [],
  } = product;

  const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);
  const currentPrice = decryptAES({ key: keygen, value: priceEncrypted || '' });
  const priceDiscount = decryptAES({ key: keygen, value: discountPriceEncrypted || '' });
  const skuReplaceFormat = skuReplace.map((product) => {
    return { ...product, price: decryptAES({ key: keygen, value: product.priceEncrypted || '' }) };
  });

  return {
    id: productId,
    name: productName,
    imageUrl,
    tags,
    priceDiscount,
    discountPercent,
    currentPrice,
    quantityInCart,
    maxQuantity,
    limitMessage,
    slug,
    sellerId,
    sellerName,
    sellerUrl,
    sellerCode,
    skuReplace: skuReplaceFormat,
    skuCode,
    isWishlist,
    isDeal: type === 'DEAL',
    labels,
    errorCode,
    errorMessage,
    warningMessage,
    type,
    productTags,
  };
}

function convertProduct(cart): Product {
  const {
    productName,
    productVolume,
    productId,
    priceLabel,
    discountPercent,
    discountDetail,
    priceEncrypted,
    discountPriceEncrypted,
    oldPriceEncrypted,
    maxQuantity,
    quantityInCart,
    imageUrl,
    isImportant,
    isSelected,
    isCanClickable,
    isFluctuated,
    labels,
    tags,
    limitMessage,
    skuCode,
    sellerCode,
    sellerId,
    type,
    errorCode,
    errorMessage,
    fluctuatedType,
    slug,
    nearExpireMessage,
    warningCode,
    warningMessage,
    productCode,
    storeCode,
    productTags,
    noneVoucherPriceEncrypted,
    retailPriceApplyVoucherEncrypt,
    skuReplace,
  } = cart;

  const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);
  const price = decryptAES({ key: keygen, value: priceEncrypted || '' });
  const discountPrice = decryptAES({ key: keygen, value: discountPriceEncrypted || '' });
  const oldPrice = decryptAES({ key: keygen, value: oldPriceEncrypted || '' });
  const noneVoucherPrice = decryptAES({ key: keygen, value: noneVoucherPriceEncrypted || '' });
  const retailPriceApplyVoucher = decryptAES({ key: keygen, value: retailPriceApplyVoucherEncrypt || '' });

  return {
    id: productId,
    name: productName,
    volume: productVolume,
    price,
    priceLabel,
    discountPrice,
    discountDetail,
    oldPrice: oldPrice || 0,
    discountPercent: discountPercent || 0,
    maxQuantity,
    quantityInCart,
    imageUrl,
    isImportant,
    isSelected: isSelected || false,
    isCanClickable: isCanClickable || false,
    labels: labels || [],
    tags: tags || [],
    limitMessage: limitMessage || '',
    slug,
    skuCode,
    sellerCode: sellerCode || '',
    sellerId: sellerId || '',
    type,
    errorCode,
    errorMessage,
    fluctuatedType,
    nearExpireMessage,
    isFluctuated: isFluctuated || false,
    warningCode,
    warningMessage,
    productCode,
    storeCode,
    productTags: productTags || [],
    noneVoucherPrice,
    retailPriceApplyVoucher,
    skuReplace: skuReplace ? skuReplace.map((item: any) => ({ ...convertCartItemReplace(item) })) : null,
  };
}

export function convertCartItem(product): Seller {
  const { sellerGroup, sellerName, sellerCode, sellerAvatar, sellerUrl, isSelected, items, totalItemDiscount = 0 } = product;

  const getStoreCode = Array.from(new Set(items?.map((item: any) => item?.storeCode)))?.[0] as string;

  return {
    products: items.map((item: any) => ({ ...convertProduct(item), sellerGroup })),
    code: sellerCode || '',
    name: sellerName,
    imageUrl: sellerAvatar,
    sellerGroup,
    isSelected: isSelected || false,
    slug: sellerUrl || '',
    totalDiscount: totalItemDiscount,
    storeCode: getStoreCode || '',
  };
}

export function convertCartStore(cart): CartStore {
  const {
    redeemApplyResult = [],
    fluctuatedItems = [],
    errorItems = [],
    discount = 0,
    totalItem = 0,
    totalItemSelected = 0,
    totalQuantity,
    totalQuantitySelected = 0,
    totalPriceEncrypted,
    totalImportantItem = 0,
    priceEncrypted,
    subPriceEncrypted,
    isSelected,
    paymentMethod,
    hasDeal = false,
  } = cart;

  const cartStoreItems: Seller[] = (cart.cartItemGroups || []).map(convertCartItem);

  const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);
  const totalPrice = decryptAES({ key: keygen, value: totalPriceEncrypted || '' }) || 0;
  const subPrice = decryptAES({ key: keygen, value: subPriceEncrypted || '' }) || 0;
  const price = decryptAES({ key: keygen, value: priceEncrypted || '' }) || 0;

  const selectedProducts = cartStoreItems.reduce((pre: Product[], cur) => {
    const newProduct = cur.products.filter((product) => product.isSelected);
    pre.push(...newProduct);
    return pre;
  }, []);

  return {
    cartNo: cart.cartNo,
    cartData: cartStoreItems,
    cartDiscounts: redeemApplyResult,
    cartFluctuates: fluctuatedItems.map((item: any) => convertProduct(item)),
    cartErrors: errorItems.map((item: any) => convertProduct(item)),
    selectedProducts,
    isOpenImportantProducts: false,
    isSelected: isSelected || false,
    totalDiscount: discount,
    totalItems: totalItem,
    totalItemsSelected: totalItemSelected,
    totalPrice,
    totalQuantity,
    totalQuantitySelected,
    totalItemsImportantSelected: totalImportantItem,
    price,
    paymentMethod,
    redeemApplyResult,
    subPrice,
    hasDeal,
  };
}

function convertCartItemReplace(itemRelace): ProductReplace {
  const { imageUrl = '', priceEncrypted, productId, productName, slug, volume, retailPriceApplyVoucherEncrypt } = itemRelace;

  const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);
  const price = decryptAES({ key: keygen, value: retailPriceApplyVoucherEncrypt || priceEncrypted || '' }) || 0;

  return {
    ...itemRelace,
    imageUrl,
    productId,
    productName,
    slug,
    volume,
    price,
  };
}



import { GET, POST, PUT } from './Clients';

const ORDER_API_PREFIX = '/marketplace/order/v2';

export async function getCartItemList({ ctx, params = { onlyQuantity: true } }) {
  return GET({
    ctx,
    url: `${ORDER_API_PREFIX}/cart-item/list`,
    params,
  });
}

function convertBody(body = {}) {
  return { ...body, source: 'thuocsi-web' };
}

export async function loadDataCart(ctx) {
  return GET({
    ctx,
    url: `${ORDER_API_PREFIX}/cart`,
  });
}

export async function checkout(body) {
  return PUT({
    url: '/marketplace/order/v2/cart/checkout',
    body: convertBody(body),
  });
}

export async function updateCart({ body, ctx }) {
  return PUT({
    ctx,
    url: `${ORDER_API_PREFIX}/cart`,
    body: convertBody(body),
  });
}

export async function updateCartItem(data) {
  return POST({
    url: `${ORDER_API_PREFIX}/cart/add`,
    body: convertBody({
		sku: data.product.sku,
		type: data?.product?.type || null,
		isDeal: data?.product?.isDeal,
		name: data?.product?.name,
		price: data?.product?.deal?.price || data?.product?.salePrice || 0,
		quantity: data.q,
		cartNo: data?.cartNo,
		page: data?.page,
		searchKey: data?.searchKey,
		sellerID: data?.sellerID || null,
		sellerCode: data?.product?.sellerCode || null,
		productId: data?.product?.productId || null,
		eventSource: data?.eventSource || '',
		eventScreen: data?.eventScreen || '',
		host: window?.location.host || '',
		recommendSKUs: data?.recommendSKUs || '',
		metadata: data?.metadata
	  }),
  });
}

export async function addCartItem(body) {
  return POST({
    url: `${ORDER_API_PREFIX}/cart/add`,
    body: convertBody(body),
  });
}

export async function updateCartItemQuickOrder(data, quantity) {
  return POST({
    url: `${ORDER_API_PREFIX}/cart/add`,
    body: convertBody({
		sku: data.product.skuCode,
		type: data.product?.type || null,
		isDeal: data.product?.isDeal,
		name: data?.product?.name,
		price: data?.product?.priceDiscount || 0,
		quantity,
		cartNo: data?.cartNo,
		page: data?.page,
		searchKey: data?.searchKey,
		sellerID: data.product.sellerId || null,
		sellerCode: data.product?.sellerCode || null,
		productId: data.product?.id || null,
		eventSource: data?.eventSource || '',
		eventScreen: data?.eventScreen || '',
		blockCode: data?.blockCode,
		metadata: {
			block_code: data?.blockCode,
		},
		host: window?.location.host || '',
	  }),
  });
}

// { sku, quantity, isImportant }
export async function updateCartItemImportant(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart-item`,
    body: convertBody(body),
  });
}

export async function removeCartItemImportant({ cartNo, skus }) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart-item/remove-important`,
    body: { cartNo, skus },
  });
}

export async function removeCartItem(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/remove`,
    body: convertBody(body),
  });
}

export async function updateRedeemCode(redeemCode) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/voucher`,
    body: convertBody({ redeemCode }),
  });
}

export async function updateDeliveryMethod(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/delivery-method`,
    body: convertBody(body),
  });
}

export async function updatePaymentMethod(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/payment-method`,
    body: convertBody(body),
  });
}

export async function updateNote(note) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart`,
    body: convertBody({ note }),
  });
}

export async function reOrder(body) {
  return POST({
    url: `${ORDER_API_PREFIX}/cart/re-order`,
    body,
  });
}

export async function confirmChangePrice(body) {
  return POST({
    url: `${ORDER_API_PREFIX}/cart/confirm`,
    body,
  });
}

export function getDeliveryLimitation({ ctx }) {
  return GET({
    ctx,
    url: `${ORDER_API_PREFIX}/delivery-limitation`,
  });
}

export function putRemoveMultiRedeem(redeemCode, cartNo) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/remove-voucher`,
    body: convertBody({ redeemCode, cartNo }),
  });
}

export function putChangeMultiRedeem(redeemCode, cartNo) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/add-voucher`,
    body: convertBody({ redeemCode, cartNo }),
  });
}
// APO-08 : tính năng đặt 1 môt giỏ hàng
// select / un-select item
// body : { isSelected, sku , isAppliedAll  }
// isSelected : true / false là có chọn hay không
// nếu theo sku thì có field sku
// nếu select all thì => isAppliedAll : true , isSelected: true
// nếu un-select all thì => isAppliedAll : false , isSelected: true
// https://buymed.atlassian.net/browse/APO-41

// chọn từng item
export async function selectCartItem({ sku, skus, isSelected, isAppliedAll, cartNo, name, quantity }) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/select`,
    body: { isSelected, sku, skus, isAppliedAll, cartNo, name, quantity },
  });
}

export async function selectCartItemV2(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart/select`,
    body,
  });
}

export async function verifyPayment({ ctx, body = {} }) {
  return POST({
    ctx,
    url: `${ORDER_API_PREFIX}/cart/verify`,
    body: convertBody(body),
  });
}

// tach don
export async function updateRefuseSplitOrder(body) {
  return PUT({
    url: `${ORDER_API_PREFIX}/cart`,
    body: convertBody(body),
  });
}

export default {
  loadDataCart,
  addCartItem,
  updateCartItem,
  updateCartItemQuickOrder,
  removeCartItem,
  updateRedeemCode,
  updateNote,
  updateCartItemImportant,
  updateDeliveryMethod,
  updatePaymentMethod,
  checkout,
  updateCart,
  reOrder,
  confirmChangePrice,
  getDeliveryLimitation,
  selectCartItem,
  selectCartItemV2,
  verifyPayment,
  updateRefuseSplitOrder,
  putRemoveMultiRedeem,
  putChangeMultiRedeem,
};

import { API_HOST_DOMAIN } from 'sysconfig';
import { GET, GET_ALL, getData } from './Clients';

const PRODUCT_API_PREFIX = '/marketplace/product/v2';

export async function loadDataIngredient(ctx) {
  const res = await GET_ALL({
    ctx,
    url: API_HOST_DOMAIN + `${PRODUCT_API_PREFIX}/ingredient/list`,
    isBasic: true,
    isAuth: false,
    limit: 100,
  });
  return getData(res);
}

export async function getIngredients({ ctx, search = '', offset = 0, limit = 100, ids = [], codes = [], getTotal = false }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/ingredient/list`,
    params: {
      offset,
      search,
      limit: codes.length > 0 ? (ids.length > 0 ? ids.length : codes.length) : limit,
      ids: ids.join(','),
      codes: codes.join(','),
      getTotal,
    },
  });
}

export async function loadDataIngredientClient({ ctx }) {
  const res = await GET_ALL({
    ctx,
    url: `${PRODUCT_API_PREFIX}/ingredient/list`,
    isBasic: true,
    isAuth: false,
  });
  return getData(res);
}

export async function loadDataIngredientClientMock() {
  return GET({
    url: '/ingredients',
    mock: true,
    isAuth: false,
  });
}

export async function getIngredientBySlug(ctx, slug) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/ingredient`,
    params: {
      q: JSON.stringify({ slug }),
    },
    isBasic: true,
  });
}

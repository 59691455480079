import { APIStatus } from '@thuocsi/customer-live-chat/src/utils/common';
import CartClientV2, { removeCartItemImportant } from 'clients/CartClientV2';
import { getFirst, isValid, isValidWithoutData } from 'clients/Clients';
import { getCartBySeller } from 'clients/ProductClientV2';
import { getScreenCartVoucherHelper } from 'clients/WebServiceClient';
import { CART_URL } from 'constants/Paths';
import Router from 'next/router';
import { convertCartStore } from 'services/ProductServiceV3';
import type { Product, Seller } from 'types/product';
import { CartStore, IOldCartItem, IRedeemApplyResult, IVoucherSuggestions } from 'types/product';
import gtag from 'utils/gtag';
import { INSIDER_CART_SOURCE, updateInsiderCartInfo } from 'utils/Insider';
import { mapScreenToEnum, mapSourceToEnum } from 'utils/MonitorUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { capitalizeText } from 'utils/StringUtils';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import WebServiceClient from '../clients/WebServiceClient';
import {
  CartParams,
  FETCH_STATUS,
  FetchStatus,
  ImportantProductPayload,
  Options,
  ProductUpdate,
  RemoveCartItemsSelectedPayload,
  RemoveCartPayload,
  SelectAllPayload,
  SelectProductPayload,
  SelectStorePayload,
  UpdateCartPayload,
} from './InterfaceZustand';

// Actions are functions which update values in your store. These are static and never change, so they aren't technically "state".
// Organising them into a separate object in our store will allow us to expose them as a single hook to be used in any our component
// without any impact on performance:
interface Actions {
  fetchCartData: () => Promise<void>;
  fetchCartDataByRouter: () => Promise<void>;
  setSelectAll: (payload: SelectAllPayload, options?: Options) => Promise<void>;
  setSelectStore: (payload: SelectStorePayload, options?: Options) => Promise<void>;
  setSelectProduct: (payload: SelectProductPayload, options?: Options) => Promise<void>;
  setImportantProduct: (payload: ImportantProductPayload, options?: Options) => Promise<void>;
  // TODO: Function updateCartItem use for increase or decrease quantity of cart
  updateCartItem: (payload: UpdateCartPayload, options?: Options) => Promise<void>;
  removeCartItemsSelected: (payload: RemoveCartItemsSelectedPayload) => Promise<void>;
  removeCartItem: (payload: RemoveCartPayload, options?: Options) => Promise<void>;
  removeImportantProducts: (payload: { cartNo: string; skus: string[] }, options?: Options) => Promise<void>;
  setSelectedLists: (payload: any) => void;
  setFirstTimeGetCart: (status: boolean) => void;
  resetFirstTimeGetCart: () => void;
  updateRedeemApplyResult: (payload: any) => void;
  updatePromosRemoved: (payload: string) => void;
  resetPromosRemoved: () => void;
  updateNextUsingPromo: (
    payload: {
      code: string;
      paymentMethod: string;
      paymentMethodName: string;
    } | null,
  ) => void;

  getIDRef: (product: Partial<Product & ProductUpdate>) => string;
  getSKUfromIDRef: (idRef: string) => string;
  updateTotalQuantity: (totalQuantity: number) => void;
  updateTriggerOpenAndScroll: () => void;
  clearTriggerOpenAndScroll: () => void;
}

type InitialCartState = CartStore & { state: FetchStatus };
type CartState = InitialCartState & { actions: Actions };

const INITIAL_STATE: InitialCartState = {
  cartNo: '',
  cartData: [],
  cartDiscounts: [],
  cartFluctuates: [],
  cartErrors: [],
  selectedProducts: [],
  isOpenImportantProducts: false,
  isSelected: false,
  totalDiscount: 0,
  totalItems: 0,
  totalPrice: 0,
  totalItemsSelected: 0,
  totalQuantitySelected: 0,
  totalQuantity: 0,
  totalItemsImportantSelected: 0,
  price: 0,
  state: FETCH_STATUS.IDLE,
  hasDeal: false,
  selectedLists: {},
  isFirstTimeGetCart: true,
  redeemApplyResult: [],
  paymentMethod: '',
  redeemCode: [],
  oldCartItems: [],
  subPrice: 0,
  promosRemoved: '',
  nextUsingPromo: null,
  lastUpdatedIDRef: '',
  triggerOpenAndScroll: 0,
  voucherSuggestions: {},
};

const INITIAL_OPTIONS = {
  isReload: true,
  isNotify: true,
};

const useCartStates = create<CartState>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    // ⬇️ separate "namespace" for actions
    actions: {
      fetchCartDataByRouter: async () => {
        const path = window.location.pathname;
        const {
          actions: { fetchCartData },
        } = get();

        // Cause in cart route, we need update all info relative to cart like: update voucher used, total price,... after we change quantity
        // => Need to call fetchCartData
        if (path === '/cart') {
          await fetchCartData();
          return;
        }

        // We only care about QUANTITY => no need call fetCartData to update all info relative to cart
        // => call cartLiteEncrypted is enough
        const res = await WebServiceClient.loadDataCartLiteEncrypted();
        if (res.status === APIStatus.OK) {
          set((_) => {
            const getFirstDataCart = getFirst(res);
            const cartStore: CartStore = convertCartStore(getFirstDataCart);

            const products = getFirstDataCart.cartItems.map((item: any) => ({
              ...item,
              skuCode: item.sku,
              quantityInCart: item.quantity,
            }));

            const cartStoreDto = {
              ...cartStore,
              cartData: [{ products }] as unknown as Seller[],
            };
            return {
              ...cartStoreDto,
              state: FETCH_STATUS.IDLE,
              isFirstTimeGetCart: false,
              totalQuantity: getFirst(res).totalQuantity,
            };
          });
          return;
        }
        set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));
      },
      // this method only call when we need update all info relative to cart info
      // avoid call it when only need renew quantity
      fetchCartData: async () => {
        try {
          let respVoucherHelper = [];
          const { isFirstTimeGetCart, promosRemoved } = get();
          const res = await getCartBySeller(isFirstTimeGetCart, promosRemoved);
          if (Router.pathname === CART_URL) {
            respVoucherHelper = await getScreenCartVoucherHelper();
          }
          const getFirstDataCart = getFirst(res);

          if (!getFirstDataCart) {
            set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));
            return;
          }

          const cartStore: CartStore = convertCartStore(getFirstDataCart);
          const oldCartItemsFormat = cartStore.cartData.map((item) =>
            item.products
              ?.filter((child) => child.isSelected)
              ?.map((product) => ({
                quantity: product.quantityInCart,
                price: product.noneVoucherPrice,
                total: Number(product.noneVoucherPrice) * Number(product.quantityInCart),
                sku: product.skuCode,
                sellerCode: product.sellerCode,
                productTags: product.productTags || [],
                productCode: product.productCode,
                isSelected: product.isSelected,
                storeCode: product.storeCode || '',
              })),
          );

          let newData = {
            ...cartStore,
            state: FETCH_STATUS.IDLE,
            isFirstTimeGetCart: false,
            oldCartItems: oldCartItemsFormat.flat() as IOldCartItem[],
            redeemCode: cartStore?.redeemApplyResult?.map((item: IRedeemApplyResult) => item.code),
          };

          const voucherSuggestions: { [key: string]: IVoucherSuggestions } = {};
          if (respVoucherHelper?.data?.length > 0) {
            respVoucherHelper.data?.forEach((suggestion: { helpers: IVoucherSuggestions[] }) => {
              suggestion?.helpers?.forEach((helper: IVoucherSuggestions) => {
                voucherSuggestions[helper.sellerCode as string] = helper;
              });
            });

            newData = {
              ...newData,
              voucherSuggestions,
            };
          } else {
            newData = {
              ...newData,
              voucherSuggestions: {},
            };
          }
          set(() => newData);

          updateInsiderCartInfo(newData.cartData, INSIDER_CART_SOURCE.CART_STATE);
        } catch (error) {
          set(() => ({
            ...INITIAL_STATE,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectAll: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { isReload } = options;
          const { isSelected } = payload;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.selectCartItemV2({
            isSelected,
            isAppliedAll: true,
            cartNo,
          });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Không thể chọn nhà bán hàng này, vui lòng thử lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectStore: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { isSelected, skus, sellerGroup } = payload;
          const { isReload } = INITIAL_OPTIONS;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          await CartClientV2.selectCartItemV2({ isSelected, skus, cartNo });

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { name, skuCode, isSelected, quantityInCart, sellerGroup } = payload;
          const { isReload } = options;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.selectCartItemV2({
            isSelected,
            sku: skuCode,
            cartNo,
            quantity: quantityInCart || 0,
            name: name || '',
          });

          if (!isValid(res)) {
            NotifyUtils.error('Chọn sản phẩm không thành công, vui lòng chọn lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setImportantProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            actions: { fetchCartData },
          } = get();
          const { cartNo, sku, type, isImportant } = payload;
          const { isReload } = options;

          const res = await CartClientV2.updateCartItemImportant({
            cartNo,
            sku,
            type,
            isImportant,
            eventSource: mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
            host: window.location.host || '',
            source: 'thuocsi-web',
          } as CartParams & ImportantProductPayload);

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Đánh dấu quan trọng sản phẩm thất bại');
            return;
          }

          if (isReload) {
            await fetchCartData();
          }
          NotifyUtils.success('Đánh dấu quan trọng thành công');
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateCartItem: async (payload, options) => {
        try {
          const {
            cartNo,
            actions: { fetchCartDataByRouter },
          } = get();
          const { idRef, newQuantity, product } = payload;
          const { isNotify, isReload } = options || INITIAL_OPTIONS;
          const { ...productWithoutTrackingMetadata } = product || {};

          const res = await CartClientV2.addCartItem({
            ...productWithoutTrackingMetadata,
            quantity: newQuantity,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource: product.eventSource || mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
            blockCode: undefined,
            metadata: {
              block_code: product?.blockCode,
              ...(product?.metadata || {}),
            },
            host: window.location.host || '',
          } as CartParams & ProductUpdate);

          if (!isValid(res)) {
            NotifyUtils.error('Cập nhật số lượng không thành công');
            return;
          }

          gtag.addToCart({ ...product, quantity: newQuantity || 0 });
          gtag.addToCartInPage({ ...product, quantity: newQuantity || 0 }, window.location.pathname);

          set((state) => ({
            ...state,
            lastUpdatedIDRef: idRef,
            isFirstTimeGetCart: true,
          }));
          //
          if (isReload) {
            setTimeout(() => {
              fetchCartDataByRouter();
            }, 0);
          }
          //
          if (isNotify) {
            const productName = capitalizeText(product.name);
            const message =
              (product?.quantity ?? 0) > newQuantity
                ? `Số lượng sản phẩm ${productName} đã được giảm.`
                : `Số lượng sản phẩm ${productName} đã được tăng.`;

            NotifyUtils.success(message);
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItemsSelected: async (payload) => {
        try {
          const {
            cartNo,
            actions: { fetchCartDataByRouter },
          } = get();

          const { skus } = payload;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          await CartClientV2.removeCartItem({
            cartNo,
            skus,
            source: 'thuocsi-web',
          });

          await fetchCartDataByRouter();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItem: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartDataByRouter },
          } = get();
          const { idRef, product } = payload;
          const { isNotify } = options;

          set((state) => ({
            ...state,
            lastUpdatedIDRef: idRef,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.removeCartItem({
            ...product,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource:
              product?.eventSource || mapSourceToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            host: window.location.host || '',
            metadata: {
              block_code: product?.blockCode,
              source: mapSourceToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
              screen: mapScreenToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            },
            // blockCode: product?.blockCode,
          } as CartParams & ProductUpdate);

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Xoá sản phẩm thất bại');
            return;
          }

          if (isNotify) {
            NotifyUtils.success(`Sản phẩm ${capitalizeText(product.name)} đã được xóa ra khỏi giỏ hàng`);
          }

          await fetchCartDataByRouter();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeImportantProducts: async (payload, options = INITIAL_OPTIONS) => {
        const {
          actions: { fetchCartDataByRouter },
        } = get();

        const res = await removeCartItemImportant({
          cartNo: payload.cartNo,
          skus: payload.skus,
        });

        const { isReload } = options;
        if (isReload) await fetchCartDataByRouter();
      },
      setSelectedLists: async (data) => {
        set(() => ({
          selectedLists: data,
        }));
      },
      setFirstTimeGetCart: (status: boolean) => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: status,
        }));
      },
      resetFirstTimeGetCart: () => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: true,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateRedeemApplyResult: (newRedeemApplyResult: any) => {
        set((state) => ({
          ...state,
          redeemApplyResult: newRedeemApplyResult,
        }));
      },
      updatePromosRemoved: (promosRemoved: string) => {
        set((state) => ({
          ...state,
          promosRemoved,
        }));
      },
      resetPromosRemoved: () => {
        set((state) => ({
          ...state,
          promosRemoved: '',
        }));
      },
      updateNextUsingPromo: (nextUsingPromo) => {
        set((state) => ({
          ...state,
          nextUsingPromo,
        }));
      },
      updateTriggerOpenAndScroll: () => {
        set((state) => ({
          ...state,
          triggerOpenAndScroll: state.triggerOpenAndScroll + 1,
        }));
      },
      clearTriggerOpenAndScroll: () => {
        set((state) => ({
          ...state,
          triggerOpenAndScroll: 0,
        }));
      },

      getIDRef: (product: Partial<Product & ProductUpdate>) => {
        return `${Math.random()}::${product.skuCode || product.sku}`;
      },
      getSKUfromIDRef: (idRef: string) => {
        return (idRef || '').split('::')[1];
      },
      updateTotalQuantity: (totalQuantity: number) => {
        set((state) => ({
          ...state,
          totalQuantity,
        }));
      },
      updateTriggerOpenAndScroll: () => {
        set((state) => ({
          ...state,
          triggerOpenAndScroll: state.triggerOpenAndScroll + 1,
        }));
      },
      clearTriggerOpenAndScroll: () => {
        set((state) => ({
          ...state,
          triggerOpenAndScroll: 0,
        }));
      },
    },
  })),
);

export const useCartActions = () => useCartStates((state) => state.actions);

export default useCartStates;

import { DEFAULT_EXPANDED_STORAGE_KEY, SEARCH_RESULT_STORAGE_KEY, VISITED_URLS_STORAGE_KEY } from 'constants/data';
import { TTL_CACHE_LOCAL } from 'sysconfig';
import { isEmpty } from 'utils/ObjectUtils';

/**
 * Cache keys for localStorage.
 */
export const CACHE_KEY = {
  CATEGORIES: 'categories',
  CHAT_SETTING: 'chatSetting',
  COUNTDOWNBAR: 'countdownBar',
  INSIDER_SETTING: 'insiderSetting',
  MANUFACTURERS: 'manufacturers',
  PROVINCES: 'provinces',
  REGIONS: 'regions',
  TAGS: 'tags',
  TICKET_REASONS: 'ticketReasons',
  TOP_MANUFACTURERS: 'topManufacturers',
};

/**
 * A cache in local storage can be invalidated via TTL and VERSION.
 * When changing data structure (E.g. update banners), change VERSION to invalidate all caches.
 */
const VERSION_KEY = 'version';

const DEFAULT_VERSION = process.env.BUILD_ID || '1';

/**
 * Get cache version from local storage.
 * @returns {string}
 */
export function getCacheVersion() {
  return localStorage.getItem(VERSION_KEY) || DEFAULT_VERSION;
}

/**
 * Set cache version to local storage.
 * @param {string?} version
 */
export function setCacheVersion(version) {
  localStorage.setItem(VERSION_KEY, version || DEFAULT_VERSION);
}

/**
 * Hide field names in serialized data.
 * @param {object} data
 * @param {string[]} fieldNames - field names to hide
 * @returns {object?}
 * @example `serializeFunc({code: 1, name: "abc"}, ['code', 'name']) => {0: 1, 1: "abc"}`
 */
export function serializeFunc(data, fieldNames) {
  const object = {};
  fieldNames?.forEach((name, index) => {
    object[index] = data[name];
  });
  return isEmpty(object) ? null : object;
}

/**
 * Recover field names from serialized data.
 * @param {object} serializedData
 * @param {string[]} fieldNames - field names to recover
 * @returns {object?}
 * @example `deserializeFunc({0: 1, 1: "abc"}, ['code', 'name']) => {code: 1, name: "abc"}`
 */
export function deserializeFunc(serializedData, fieldNames) {
  const object = {};
  fieldNames?.forEach((name, index) => {
    object[name] = serializedData[index];
  });
  return isEmpty(object) ? null : object;
}

/**
 * Utility function to get data with expiration + version in localStorage
 * @param {string} key - the cache key
 * @param {object} param1
 * @param {string?} param1.v - the cache VERSION
 * @returns
 */
export function getWithExpiry(key = '', { v = getCacheVersion() } = {}) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry || item.v !== v) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

/**
 * Utility function to set data with expiration + version in localStorage
 * @param {string} key - the cache key
 * @param {*} value
 * @param {object} param2
 * @param {string?} param2.ttl - the cache time-to-live in milliseconds
 * @param {string?} param2.v - the cache VERSION
 */
export function setWithExpiry(key, value, { ttl = TTL_CACHE_LOCAL * 1000, v = getCacheVersion() } = {}) {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
    v,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Utility function to clear all cache, excluding some special keys in localStorage
 */
export function clearLocalStorage() {
  if (!window || !window.localStorage) return;

  const preservedPrefixes = ['ins', 'sp', 'step', 'time-rating', 'finish', 'lastOpenRemindDate', 'dropDownMenuList'];

  for (const key of Object.keys(localStorage)) {
    if (!preservedPrefixes.some((prefix) => key.startsWith(prefix))) {
      localStorage.removeItem(key);
    }
  }
  localStorage.setItem('logout', new Date());
  localStorage.setItem(VISITED_URLS_STORAGE_KEY, '[]');
  localStorage.removeItem(DEFAULT_EXPANDED_STORAGE_KEY);
  localStorage.removeItem(SEARCH_RESULT_STORAGE_KEY);
}
